@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/blocks_shared/additional_js_css/additional_js_css.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/blocks_shared/breadcrumbs/breadcrumbs.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/layout/base-layout/base-layout.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/layout/page-layout/page-layout.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/pages/article/article-category-box/article-category-box.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/pages/article/article-category.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/pages/article/article-item-box/article-item-box.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/pages/article/article-item.scss";
@import "C:/xampp/htdocs/websites/mizug-avir/app/resources/pages/yt/jquery.mb.YTPlayer.min.scss";

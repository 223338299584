@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "fonts/slickslider/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @return url('img/slickslider/'+$url);
}

@function slick-font-url($url) {
    @return url('fonts/slickslider/'+$url);
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    z-index: 1000;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: 25px;
    [dir="rtl"] & {
        left: auto;
        right: 25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 25px;
    [dir="rtl"] & {
        left: 25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    // margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      display: none;
  }

  display: none;

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.footer {
	position: relative;
	width: 100%;
	padding: 80px 0 10px;
	z-index: 1;
    margin-top: 40px;
	height: auto;
    min-height: 250px;
    background-color: #29a3cf;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 50px rgba(255, 255, 255, 0.25);
	@include respond-to('large') {
        padding: 20px 30px 10px;
	}
}
.footer p.title {
    font-size: 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 35px;
    @include respond-to('medium') {
        font-size: 25px;
    }
}
.footerform .form-group input{
	width: 100%;
}
.footerform .form-group input.checkbox_footer{
	width: 21px !important;
    display: inline-block;
    margin: 0px 1px -8px 10px !important;
    vertical-align: bottom;
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	&__right {
		width: 22%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 250px;
			margin: 0 auto 15px;
			text-align: center;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
	&__left {
		width: 78%;
		float: $left;
		@include respond-to('1100') {
			float: none;
			width: 100%;
		}
	}
}

.link-col {
	float: $right;
	width: 33.33%;
	@include respond-to('small') {
		width: 50%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #fff;
	margin-top: 40px;
	margin-bottom: 20px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	// text-align: center;
	@include respond-to('medium') {
		text-align: center;
	}
	& img {
		max-width: 216px;
		@include respond-to('medium') {
			max-width: 150px;
		}
	}
}

.ftr-contact {
	padding-top: 30px;
	padding-#{$left}: 5px;
	@include respond-to('1100') {
		max-width: 250px;
		margin: 10px auto 10px;
		padding-top: 15px;
		padding-#{$left}: 0px;
	}
	@include respond-to('medium') {
		padding-top: 0;
	}
	&__item {
		display: block;
		margin-bottom: 1px;
		color: #fff;
		text-align: $right;
		@include link_no-hover(#fff);
		& i {
			display: inline-block;
			vertical-align: middle;
			width: 15%;
			margin-#{$left}: -4px;
			color: #50b4d8;
			font-size: 20px;
		}
		& span {
			display: inline-block;
			vertical-align: middle;
			padding-#{$right}: 2px;
			width: 85%;
			font-size: 20px;
			font-weight: 400;
			@include respond-to('medium') {
				// font-size: 15px;
				// font-weight: 300;
			}
		}
	}
}

.site-footer__credit-holder {
	position: relative;
	padding: 0px 0 20px;
	@include respond-to('medium') {
		padding: 0px 0 10px;
	}
}

.ftr-facebook {
	position: absolute;
	top: 10px;
	right: 0;
}

.ftr-insta {
	position: absolute;
	
	right: 56px;
    top: 15px;
} 
.form-control{
    box-shadow: none !important;
}
.inside_cont form{
	width: 100%;
	margin-bottom: 59px;
	display: flex;
    padding: 0px 30px;
	justify-content: space-between;
	align-items: center;
	@include respond-to('medium') {
		display: block;
	}
	input{
			width: 21%;
			border: none;
			border-bottom: 1px solid #ffffff;
			height: 50px;
			color: #ffffff;
			font-size: 20px;
			font-weight: 600;
			background-color: transparent;
			@include respond-to('medium') {
				width: 100%;
			}
			&::placeholder{
					color: #ffffff;
					font-size: 20px;
					font-weight: 600;
			}
	}
	button{
			width: 100%;
            padding: 0px 30px;
			max-width: 170px;
			height: 50px;
			background-color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			color: #161748;
			font-size: 18px;
			font-weight: 800;
			@include respond-to('medium') {
				margin-top: 18px;
			} 
	}
	.check_form {
		@include respond-to('medium') {
			width: 5% !important;
			margin-top: 17px !important;
			display: inline-block;
		} 

	}
	.lable_form {
		@include respond-to('medium') {
			width: 80%;
		}  

	}
}
.footer {
	
	
	.bottom{
			margin-top: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 25px;
	}
}